const transitionDelay = 0;

exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    if (location.action === "PUSH" && !location?.state?.preventScroll) {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
    } else {
        const savedPosition = location?.state?.preventScroll ? location?.state?.scrollPosition : getSavedScrollPosition(location);
        window.setTimeout(
            () => window.scrollTo(...(savedPosition || [0, 0])),
            transitionDelay
        );
    }
    return false;
};

exports.onInitialClientRender = () => {
    window.scrollTo(0, 0);
};
