exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-apply-community-jsx": () => import("./../../../src/pages/apply-community.jsx" /* webpackChunkName: "component---src-pages-apply-community-jsx" */),
  "component---src-pages-authors-jsx": () => import("./../../../src/pages/authors.jsx" /* webpackChunkName: "component---src-pages-authors-jsx" */),
  "component---src-pages-communities-jsx": () => import("./../../../src/pages/communities.jsx" /* webpackChunkName: "component---src-pages-communities-jsx" */),
  "component---src-pages-editors-jsx": () => import("./../../../src/pages/editors.jsx" /* webpackChunkName: "component---src-pages-editors-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-peer-review-jsx": () => import("./../../../src/pages/peer-review.jsx" /* webpackChunkName: "component---src-pages-peer-review-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-publications-jsx": () => import("./../../../src/pages/publications.jsx" /* webpackChunkName: "component---src-pages-publications-jsx" */),
  "component---src-pages-technology-jsx": () => import("./../../../src/pages/technology.jsx" /* webpackChunkName: "component---src-pages-technology-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-waitinglist-jsx": () => import("./../../../src/pages/waitinglist.jsx" /* webpackChunkName: "component---src-pages-waitinglist-jsx" */),
  "component---src-templates-community-editors-index-jsx": () => import("./../../../src/templates/community-editors/index.jsx" /* webpackChunkName: "component---src-templates-community-editors-index-jsx" */),
  "component---src-templates-guideline-index-jsx": () => import("./../../../src/templates/guideline/index.jsx" /* webpackChunkName: "component---src-templates-guideline-index-jsx" */),
  "component---src-templates-single-about-index-jsx": () => import("./../../../src/templates/single-about/index.jsx" /* webpackChunkName: "component---src-templates-single-about-index-jsx" */),
  "component---src-templates-single-community-index-jsx": () => import("./../../../src/templates/single-community/index.jsx" /* webpackChunkName: "component---src-templates-single-community-index-jsx" */),
  "component---src-templates-single-publication-index-jsx": () => import("./../../../src/templates/single-publication/index.jsx" /* webpackChunkName: "component---src-templates-single-publication-index-jsx" */)
}

